import { FormattedMessage } from 'react-intl';
import { Box, Button, Stack } from '@mantine/core';
import { zodResolver } from '@hookform/resolvers/zod';

import { PasswordField, TextField } from 'modules/form';

import { useLoginForm } from '../../hooks';
import { loginFormKey, loginFormSchema } from './LoginForm.schema';

export const LoginForm = () => {
    const {
        handleSubmit,
        handleFormSubmit,
        register,
        control,
        formState: { errors },
    } = useLoginForm({
        resolver: zodResolver(loginFormSchema),
    });

    return (
        <Box mt="6.25rem" w="100%">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Stack>
                    <TextField
                        control={control}
                        name={loginFormKey.identifier}
                        errors={errors}
                        type="email"
                        label={<FormattedMessage id="login.form.email" />}
                    />
                    <PasswordField
                        register={register}
                        name={loginFormKey.password}
                        errors={errors}
                        label={<FormattedMessage id="login.form.password" />}
                    />
                    <Button type="submit" variant="primary" w="80%" m="0 auto" mt="lg">
                        <FormattedMessage id="login.form.submit" />
                    </Button>
                </Stack>
            </form>
        </Box>
    );
};
