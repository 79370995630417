import { FormattedMessage } from 'react-intl';
import { Text } from '@mantine/core';
import { DatePickerInput, type DatePickerInputProps } from '@mantine/dates';
import { useController, FieldValues } from 'react-hook-form';
import type { Control, DeepMap, FieldError, Path, RegisterOptions } from 'react-hook-form';

import type { MessageKey } from 'translations';

interface DateInputFieldProps<TFormValues extends FieldValues> extends DatePickerInputProps {
    name: Path<TFormValues>;
    control: Control<TFormValues>;
    rules?: RegisterOptions;
    errors?: Partial<DeepMap<TFormValues, FieldError>>;
}

export const DateInputField = <TFormValues extends Record<string, unknown>>({
    name,
    control,
    rules,
    errors,
    ...rest
}: DateInputFieldProps<TFormValues>) => {
    const {
        field,
        fieldState: { invalid, error },
    } = useController<TFormValues>({
        name,
        control,
        rules,
    });

    return (
        <DatePickerInput
            name={name}
            value={field.value as Date}
            onChange={field.onChange}
            onBlur={field.onBlur}
            aria-invalid={!!error || invalid}
            error={error && <Text>{<FormattedMessage id={error.message as MessageKey} />}</Text>}
            {...rest}
        />
    );
};
