import { createStyles } from '@mantine/core';

export default createStyles(() => ({
    container: {
        maxWidth: 315,
        width: '100%',

        height: '100vh',

        margin: '0 auto',

        flexDirection: 'column',
    },
}));
