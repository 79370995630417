import { z } from 'zod';

import * as validators from 'modules/form/validators';

export const loginFormSchema = z.object({
    identifier: validators.email,
    password: validators.required,
} as const);

export type LoginFormSchemaType = z.infer<typeof loginFormSchema>;

export const loginFormKey = loginFormSchema.keyof().Enum;
