import { createStyles, getStylesRef } from '@mantine/core';

interface StyleOptions {
    mih?: number | string;
}

export default createStyles((theme, { mih = 100 }: StyleOptions) => ({
    dropzoneRoot: {
        border: 'none',
        display: 'flex',
        minHeight: mih,
        [`&[data-accept] .${getStylesRef('uploadCallout')}`]: {
            color: theme.white,
        },
    },
    dropzoneInner: {
        display: 'flex',
        flexGrow: 1,
    },
    dropzoneStack: {
        flexGrow: 1,
    },
    uploadCallout: {
        ref: getStylesRef('uploadCallout'),
        color: theme.colors.accentPrimary[0],
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
}));
