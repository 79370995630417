import { FormattedMessage } from 'react-intl';
import { NumberInput, type NumberInputProps, Text } from '@mantine/core';
import {
    type Control,
    type FieldErrors,
    type Path,
    type RegisterOptions,
    useController,
    type FieldValues,
} from 'react-hook-form';

import type { MessageKey } from 'translations';

interface NumberFieldProps<TFormValues extends FieldValues> extends NumberInputProps {
    name: Path<TFormValues>;
    rules?: RegisterOptions;
    control: Control<TFormValues>;
    errors?: FieldErrors<TFormValues>;
}

export const NumberField = <TFormValues extends Record<string, unknown>>({
    name,
    control,
    rules,
    errors,
    ...rest
}: NumberFieldProps<TFormValues>) => {
    const {
        fieldState: { error },
        field,
    } = useController<TFormValues>({
        name,
        control,
        rules,
    });

    const hasError = !!error;
    const value = typeof field.value === 'number' && !isNaN(field.value) ? field.value : '';

    return (
        <NumberInput
            aria-invalid={hasError}
            {...field}
            value={value}
            error={error && <Text>{<FormattedMessage id={(error as any).message as MessageKey} />}</Text>}
            {...rest}
        />
    );
};
