/* eslint-disable camelcase */
import { z } from 'zod';

import { File } from 'utils/file';

import { formError } from '../errors';

export const string = z.string({
    required_error: formError.required,
});

export const required = string.nonempty(formError.required);

export const email = required.email({ message: formError.email });

export const date = z.date({ required_error: formError.required });

export const bool = z.boolean({
    required_error: formError.required,
});

export const requiredNumber = z.number({
    required_error: formError.required,
});

export const requiredInt = z
    .number({
        required_error: formError.required,
    })
    .int();
export const int = z
    .number({
        required_error: formError.required,
    })
    .optional();

export const requiredFile = z.instanceof(File, { message: formError.required });

export const coercedRequiredInt = z.coerce.number({ required_error: formError.required }).int();
