import { Select, type SelectProps } from '@mantine/core';
import { useController, type Control, type FieldErrors, type Path, type FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { MessageKey } from 'translations';

interface SelectFieldProps<TFormValues extends FieldValues> extends SelectProps {
    name: Path<TFormValues>;
    control: Control<TFormValues>;
    errors?: FieldErrors<TFormValues>;
    data: SelectProps['data'];
}

export const SelectField = <TFormValues extends Record<string, unknown>>({
    control,
    name,
    data,
    ...rest
}: SelectFieldProps<TFormValues>) => {
    const {
        field,
        fieldState: { error },
    } = useController({ control, name });

    const value = typeof field.value === 'string' ? field.value : null;

    return (
        <Select
            data={data}
            error={error && <FormattedMessage id={error.message as MessageKey} />}
            {...rest}
            {...field}
            value={value}
        />
    );
};
