import { FormattedMessage } from 'react-intl';
import { Checkbox, type CheckboxProps, Text } from '@mantine/core';
import type { DeepMap, FieldError, Path, RegisterOptions, UseFormRegister, FieldValues } from 'react-hook-form';
import get from 'lodash/get';

import type { MessageKey } from 'translations';

interface CheckboxFieldProps<TFormValues extends FieldValues> extends CheckboxProps {
    name: Path<TFormValues>;
    rules?: RegisterOptions;
    register?: UseFormRegister<TFormValues>;
    errors?: Partial<DeepMap<TFormValues, FieldError>>;
}

export const CheckboxField = <TFormValues extends Record<string, unknown>>({
    name,
    register,
    rules,
    errors,
    ...rest
}: CheckboxFieldProps<TFormValues>) => {
    const error = get(errors, name);
    const hasError = !!(errors && error);

    return (
        <Checkbox
            name={name}
            aria-invalid={hasError}
            {...(register && register(name, rules))}
            error={error && <Text>{<FormattedMessage id={(error as any).message as MessageKey} />}</Text>}
            {...rest}
        />
    );
};
