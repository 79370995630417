import { ActionIcon, Flex, Text } from '@mantine/core';
import { TrashIcon } from '@radix-ui/react-icons';

interface FileListItemProps {
    fileName: string;
    handleDelete: () => void;
}

export const FileListItem = ({ fileName, handleDelete }: FileListItemProps) => (
    <Flex justify="center" gap=".5rem" align="center">
        <Text underline>{fileName}</Text>
        <ActionIcon color="error" size="md" onClick={handleDelete}>
            <TrashIcon width={22} height={22} />
        </ActionIcon>
    </Flex>
);
