import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';

import { Center, Loader } from '@mantine/core';

import { config } from 'config';

interface AuthenticatedProps {
    children: ReactNode;
}

export const Authenticated = ({ children }: AuthenticatedProps) => {
    const { status } = useSession();
    const router = useRouter();

    if (status === 'unauthenticated') {
        router.push(config.routes.login);
    }

    if (status === 'authenticated') {
        return <>{children}</>;
    }

    return (
        <Center h="100vh">
            <Loader />
        </Center>
    );
};
