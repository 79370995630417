import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';

import { useForm } from 'react-hook-form';

import type { UseFormProps } from 'react-hook-form';

import { config } from 'config';

import { API_ERRORS, formError } from 'modules/form/errors';

import type { LoginFormSchemaType } from '../components/LoginForm/LoginForm.schema';
import { loginFormKey } from '../components/LoginForm/LoginForm.schema';
import type { MessageKey } from '../../../translations';

export function useLoginForm(props?: UseFormProps<LoginFormSchemaType>) {
    const { setError, ...rest } = useForm<LoginFormSchemaType>(props);

    const router = useRouter();

    async function handleFormSubmit(data: LoginFormSchemaType) {
        try {
            const result = await signIn('credentials', { ...data, redirect: false });

            if (!result) return;

            if (result.error) {
                const parsedError = JSON.parse(result.error) as Error;

                if (parsedError.name === API_ERRORS.VALIDATION_ERROR) {
                    setError(loginFormKey.password, { message: formError.wrongCredentials });
                } else if (parsedError.name === API_ERRORS.INVALID_ROLE) {
                    setError(loginFormKey.identifier, { message: formError.invalidAppRole });
                }
            }

            if (result.ok) {
                await router.push(config.routes.events);
            }
        } catch (e) {
            const message: MessageKey = 'error.generalDescription';

            setError(loginFormKey.password, { message });
        }
    }

    return { handleFormSubmit, ...rest };
}
