import { FormattedMessage } from 'react-intl';
import { Text, TextInput, type TextInputProps } from '@mantine/core';
import { Path, FieldErrors, FieldValues, Control, useController } from 'react-hook-form';
import get from 'lodash/get';

import type { MessageKey } from 'translations';

interface TextFieldProps<TFormValues extends FieldValues> extends TextInputProps {
    name: Path<TFormValues>;
    control?: Control<TFormValues>;
    errors?: FieldErrors<TFormValues>;
}

export const TextField = <TFormValues extends Record<string, unknown>>({
    name,
    control,
    errors,
    ...rest
}: TextFieldProps<TFormValues>) => {
    const error = get(errors, name);
    const hasError = !!(errors && error);

    const { field: registerValues } = useController({ control, name });

    return (
        // @ts-expect-error value could be of a weird type
        //   could be improved by specifying, that name can only refer to a string field
        <TextInput
            aria-invalid={hasError}
            {...registerValues}
            error={error && <Text>{<FormattedMessage id={error.message as MessageKey} />}</Text>}
            {...rest}
        />
    );
};
