import { Box, Center, Title } from '@mantine/core';
import { FormattedMessage } from 'react-intl';

import useStyles from './LoginPage.styles';
import { LoginForm } from '../LoginForm';

export const LoginPage = () => {
    const { classes } = useStyles();

    return (
        <Center className={classes.container}>
            <Box mt="-6.25rem">
                <Title order={1}>
                    <FormattedMessage id="login.companyName" />
                </Title>
                <Title order={3} mt="xl" align="center">
                    <FormattedMessage id="login.title" />
                </Title>
            </Box>
            <LoginForm />
        </Center>
    );
};
