import { Text, type TextareaProps, Textarea } from '@mantine/core';
import type { FieldErrors, Path, RegisterOptions, UseFormRegister, FieldValues } from 'react-hook-form';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import type { MessageKey } from 'translations';

interface TextareaFieldProps<TFormValues extends FieldValues> extends TextareaProps {
    name: Path<TFormValues>;
    rules?: RegisterOptions;
    register?: UseFormRegister<TFormValues>;
    errors?: FieldErrors<TFormValues>;
}

export const TextareaField = <TFormValues extends Record<string, unknown>>({
    name,
    register,
    rules,
    errors,
    ...rest
}: TextareaFieldProps<TFormValues>) => {
    const error = get(errors, name);
    const hasError = !!(errors && error);

    return (
        <Textarea
            name={name}
            aria-invalid={hasError}
            {...(register && register(name, rules))}
            error={error && <Text>{<FormattedMessage id={error.message as MessageKey} />}</Text>}
            {...rest}
        />
    );
};
