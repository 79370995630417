export enum API_ERRORS {
    VALIDATION_ERROR = 'ValidationError',
    INVALID_ROLE = 'InvalidRole',
}

export const formError = {
    required: 'form.error.required',
    invalidAppRole: 'form.error.invalidAppRole',
    wrongCredentials: 'form.error.wrongCredentials',
    incorrectCurrentPassword: 'form.error.incorrectCurrentPassword',
    email: 'form.error.email',
    repeatedAttributeNames: 'form.error.repeatedAttributeNames',
    atLeastOneIdentifierRequired: 'form.error.atLeastOneIdentifierRequired',
    minGuests: 'form.error.minGuests',
    minGuestsForInvitation: 'form.error.minGuestsForInvitation',
    minVipGuests: 'form.error.minVipGuests',
    atLeastIdentifierKeyRequired: 'form.error.atLeastOneIdentifierKeyRequired',
} as const;
