import dynamic from 'next/dynamic';

import type { WysiwygField as WysiwygFieldComponent } from './WysiwygField';

export const WysiwygField = dynamic(
    async () => {
        const file = await import('./WysiwygField');

        return file.WysiwygField;
    },
    { ssr: false },
) as typeof WysiwygFieldComponent;
