import { Stack, Text } from '@mantine/core';
import { Dropzone, type DropzoneProps, type FileWithPath } from '@mantine/dropzone';
import { FormattedMessage } from 'react-intl';
import type { ForwardedRef } from 'react';
import type { FieldError } from 'react-hook-form';

import type { MessageKey } from 'translations';

import useStyles from './DropzoneField.styles';

interface DropzoneFieldProps {
    error?: FieldError;
    handleFilesDrop: (files: FileWithPath[]) => void;
    accept?: DropzoneProps['accept'];
    openRef?: ForwardedRef<() => void>;
    maxFiles?: number;
    placeholderKey: MessageKey;
    mih?: string | number;
    loading?: boolean;
}

export const DropzoneField = ({
    mih,
    error,
    handleFilesDrop,
    accept,
    openRef,
    maxFiles,
    placeholderKey,
    loading,
}: DropzoneFieldProps) => {
    const { classes } = useStyles({ mih });

    return (
        <Dropzone
            onDrop={handleFilesDrop}
            maxFiles={maxFiles}
            accept={accept}
            classNames={{ root: classes.dropzoneRoot, inner: classes.dropzoneInner }}
            openRef={openRef}
            loading={loading}
        >
            <Stack align="center" justify="center" className={classes.dropzoneStack}>
                <Text align="center">
                    <FormattedMessage
                        id={placeholderKey}
                        values={{ u: chunks => <span className={classes.uploadCallout}>{chunks}</span> }}
                    />
                </Text>
                {error && (
                    <Text color="error" align="center">
                        <FormattedMessage id={error.message as MessageKey} />
                    </Text>
                )}
            </Stack>
        </Dropzone>
    );
};
